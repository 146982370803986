import React from 'react';
import {  kollektor_wp_einzel, technikkatalog_emissionen, technikkatalog_jahre, foerderungen, wp_foerder } from '../ipkw/NRWTKConsts';
import {  building_map, auslegungstemp } from '../ipkw/IPKWConsts';
import {  getLeistungStyle,getCalculationHelper} from './wikiUtil';

export const fkwp_einzel_view = ( selectedBuilding) => {
    return (
        <>
            {/* Technische Kennzahlen */}
            <div>
                <h2>Technischer Überblick</h2>
            </div>
            <h3>Rahmenbedingungen {selectedBuilding}:</h3>
            <div className="fact-sheet-item">
                <h4>
                    Auslegungstemperatur: {auslegungstemp}°C
                    <br />
                    Leistungsbereich:{" "}
                    {
                        building_map.find((building) => building.name === selectedBuilding).leistungsbereich_l +
                        " - " +
                        building_map.find((building) => building.name === selectedBuilding).leistungsbereich_h +
                        " kW"
                    }
                    <br />
                    Quellen:{" "}
                    <br />
                    <a
                        href="https://www.kww-halle.de/wissen/bundesgesetz-zur-waermeplanung"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Technikkatalog Wärmeplanung Bundesgesetz zur Wärmeplanung
                    </a>
                    <br />
                    <a
                        href="https://www.bafa.de/DE/Energie/Effiziente_Gebaeude/Foerderprogramm_im_Ueberblick/foerderprogramm_im_ueberblick_node.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        BAFA BEG Förderprogramm Überblick
                    </a>
                </h4>
            </div>
                {/* Example calculation */}
                {getCalculationHelper(selectedBuilding, kollektor_wp_einzel)}
            <h3>Kennzahlen:</h3>
    
            <h4>Technik:</h4>
            <div className="table-container">
                <table className="wiki-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th colSpan={kollektor_wp_einzel.length} style={{ textAlign: "center" }}>
                                Thermische Leistung (kWth)
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <th key={eintrag.leistung} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>{eintrag.leistung}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>JAZ saniert mit Fußbodenheizung:</td>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <td key={`jazFussboden-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>
                                    {eintrag.jazFussboden}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>JAZ unsaniert:</td>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <td key={`jazUnsaniert-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>
                                    {eintrag.jazUnsaniert}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>Empfohlene Pufferspeicher Kapazität (L):</td>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <td key={`pufferKapazitaet-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>
                                    {eintrag.pufferKapazitaet}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>Lebensdauer Anlage (Jahre):</td>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <td key={`lebensdaer-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>
                                    {eintrag.lebensdauer}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>Lebensdauer Erschließung Wärmequelle  Flächenkollektor(Jahre):</td>
                            {kollektor_wp_einzel.map((eintrag) => (
                                <td key={`lebensdaer-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung,selectedBuilding)}>
                                    {eintrag.lebensdauerErschliessung}
                                </td>
                            ))}
                        </tr>
                    
                    </tbody>
                </table>
            </div>
            <h4>Emissionen:</h4>
            <div className="table-container">
                <table className="wiki-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th colSpan={9} style={{ textAlign: "center" }} key={"Jahr"}>
                                Jahr
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            {technikkatalog_jahre.map((eintrag) => (
                                <th key={eintrag}>{eintrag}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td key="Emissfaktor">Emissionsfaktor <br />(g CO2-Äquivalenten pro kWh Endenergie):</td>
                            {technikkatalog_emissionen.find(
                                (eintrag) => eintrag.energietraeger === "Strom-Mix-D"
                            ).emissionen.map((eintrag, index) => (
                                <td key={`emissfaktor-${index}`}>
                                    {eintrag}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
    
            <h3>Finanzen:</h3>
                <h4>Kosten:</h4>
                <div className="table-container">
                    <table className="wiki-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th colSpan={kollektor_wp_einzel.length} style={{ textAlign: "center" }}>
                                    Thermische Leistung (kWth)
                                </th>
                            </tr>
                            <tr>
                                <th></th>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <th key={eintrag.leistung} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>{eintrag.leistung}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <td colSpan={10} style={{ fontWeight: "bold", textAlign: "right" }}>
                                Kosten (2023 €Real, ohne MWST.)
                            </td>
                            <tr>
                                <td>Spezifische Investitionskosten nur Anlage (€/kW):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`spezInvestKosten-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.spezInvestKosten}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Anlagenkosten (%):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`anlagenKostenProzent-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.anlagenKostenProzent}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Installationskosten (%):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`installationsKostenProzent-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.installationsKostenProzent}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Erschließungskosten Wärmequelle Flächenkollektoren (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`erschliessungskostenWQ-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.erschliessungskostenWQ}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Spezifische Investkosten Wärmepumpe Gesamt (€/kWh):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`spezInvestKostenGesamt-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.spezInvestKostenGesamt}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Investkosten Wärmepumpe gesamt (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`investKostenGesamtAnlage-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.investKostenGesamtAnlage}
                                    </td>
                                ))}
                            </tr>
                            <td colSpan={10} style={{ fontWeight: "bold" }}>
                                Zusäztliche Kosten (2023 €Real, ohne MWST.)
                            </td>
    
                            <tr>
                                <td>Jährliche Fixkosten (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`jaehrlicheFixkosten-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.jaehrlicheFixkosten}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Geringinvestive Kosten pro kW (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`geringinvestiveKostenProKW-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.geringinvestiveKostenProKW}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Geringinvestive Kosten (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`geringinvestiveKosten-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.geringinvestiveKosten}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Pufferspeicher Kosten (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`pufferspeicherKosten-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.pufferspeicherKosten}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>Investitionskosten pro Liter (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`investKostenProL-${eintrag.leistung}`} style={getLeistungStyle(eintrag.leistung, selectedBuilding)}>
                                        {eintrag.investKostenProL}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td style={{ fontWeight: "bold" }}>Investitionskosten Gesamt (€):</td>
                                {kollektor_wp_einzel.map((eintrag) => (
                                    <td key={`investKostenGesamt-${eintrag.leistung}`} style={{ ...getLeistungStyle(eintrag.leistung, selectedBuilding), fontWeight: "Bold" }}>
                                        {eintrag.investKostenGesamt}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <h4>Mögliche BEG Förderungen (Maximalfördersatz 70%):</h4>
                <div className="table-container">
                    <table className="wiki-table">
                        <thead>
                            <tr>
                                <th>Durch-führer</th>
                                <th>Richt-linien Nr.</th>
                                <th>Einzel-maßnahme</th>
                                <th>Grund-fördersatz</th>
                                <th>iSFP-Bonus</th>
                                <th>Effizienz-bonus</th>
                                <th>KSG-bonus</th>
                                <th>Einkommens-bonus</th>
                                <th>Fach-planung und Bau-begleitung</th>
                            </tr>
                        </thead>
                        <tbody>
                            {foerderungen.filter(
                                (eintrag) =>
                                    wp_foerder.find((foerder) => foerder === eintrag.einzelmassnahme)
                            ).map((eintrag, index) => (
                                <tr key={index}>
                                    <td>{eintrag.durchfuehrer}</td>
                                    <td>{eintrag.richtlinienNr}</td>
                                    <td>{eintrag.einzelmassnahme}</td>
                                    <td>{eintrag.grundfoerdersatz}</td>
                                    <td>{eintrag.isfpBonus}</td>
                                    <td>{eintrag.effizienzBonus}</td>
                                    <td>{eintrag.klimaGeschwindigkeitsBonus}</td>
                                    <td>{eintrag.einkommensBonus}</td>
                                    <td>{eintrag.fachplanungUndBaubegleitung}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </>
    )};

