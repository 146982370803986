import { technikkatalog_jahre, technikkatalog_emissionen, gas_einzel,pellets_einzel } from '../../NRWTKConsts';
import { bedarfMitSanierung ,energyToLeistung,pickNRWTKRowByLeistung} from './CalculatorUtil';


export function getVerbrennungEnergieBedarfObjekt(gesamtVerbrauch, scenarioConfig, heatSource, years) {
    const leistung = energyToLeistung(gesamtVerbrauch);
    let dataRow;
    if (heatSource === 'Gaskessel') {
        dataRow = pickNRWTKRowByLeistung(leistung, gas_einzel);
    } else if (heatSource === 'Holzpellets') {
        dataRow = pickNRWTKRowByLeistung(leistung, pellets_einzel);
    } else {
        console.error('Unknown heat source for Verbrennung:', heatSource);
        return [];
    }

    const efficiency = dataRow.wirkungsgrad / 100;
    const fuelRequired = gesamtVerbrauch / efficiency;

    return bedarfMitSanierung(
        fuelRequired,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );

}

export function getVerbrennungCo2(bedarfTimeline, heatSource) {
    let energyCarrier;
    if (heatSource === 'Gaskessel') {
        energyCarrier = 'Erdgas';
    } else if (heatSource === 'Holzpellets') {
        energyCarrier = 'Holz';
    } else {
        console.error('Unknown heat source for Verbrennung:', heatSource);
        return [];
    }

    const emissionData = technikkatalog_emissionen.find(
        (item) => item.energietraeger === energyCarrier
    );

    if (!emissionData) {
        console.error('Emission data not found for energy carrier:', energyCarrier);
        return [];
    }

    const years = bedarfTimeline.map((item) => item.year);

    const emissionFactors = {};

    years.forEach((year) => {
        const index = technikkatalog_jahre.indexOf(year);
        if (index !== -1) {
            emissionFactors[year] = emissionData.emissionen[index] / 1000;
        } else {
            emissionFactors[year] =
                emissionData.emissionen[emissionData.emissionen.length - 1] / 1000;
        }
    });


    return bedarfTimeline.map((item) => {
        const emissionFactor = emissionFactors[item.year];
        const emissions = emissionFactor * item.requiredBedarf;
        return {
            year: item.year,
            emission: emissions,
        };
    });
}