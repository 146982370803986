export const effizienzGasKessel = 0.95;
export const effizienzPelletsKessel = 0.9;

export const chartYears = [2025, 2030, 2035, 2040, 2045]

export const ipkwBuildingTypes = ['EFH', 'MFH', 'GMFH', 'NWG'];
export const ipkwBuildingYears = [1945, 1980, 2000, 2010, 2020];

export const kwpNRWBuildingTypes = ['RH', 'EFH', 'MFH', 'GMFH', 'NWG'];
export const kwpNrwYears = ['1900', '1945', '1960', '1970', '1980', '1985', '2015', '2015+'];

export const yearColours = [
    [0, 1, 4, 6, 7],
    [0, 2, 4, 6, 8],
    [0, 2, 3, 5, 8],
    [1, 2, 4, 6, 8],
]

export const eekMapping = [
    { sanierungstand: 0, eek: 'H', maxBedarf: 30, temperatureCSSIndex: 0 },
    { sanierungstand: 1, eek: 'G', maxBedarf: 50, temperatureCSSIndex: 1 },
    { sanierungstand: 2, eek: 'F', maxBedarf: 75, temperatureCSSIndex: 2 },
    { sanierungstand: 3, eek: 'E', maxBedarf: 100, temperatureCSSIndex: 3 },
    { sanierungstand: 4, eek: 'D', maxBedarf: 130, temperatureCSSIndex: 4 },
    { sanierungstand: 5, eek: 'C', maxBedarf: 160, temperatureCSSIndex: 5 },
    { sanierungstand: 6, eek: 'B', maxBedarf: 200, temperatureCSSIndex: 6 },
    { sanierungstand: 7, eek: 'A', maxBedarf: 250, temperatureCSSIndex: 7 },
    { sanierungstand: 8, eek: 'A+', maxBedarf: 250, temperatureCSSIndex: 8 },

]

export const legendMapping = [
    { combinedType: 'WGB1945', baualter: 1945, waermebedarf: 150, temperatureScale: 2 },
    { combinedType: 'WGB1980', baualter: 1980, waermebedarf: 200, temperatureScale: 0 },
    { combinedType: 'WGB2000', baualter: 2000, waermebedarf: 160, temperatureScale: 1 },
    { combinedType: 'WGB2010A', baualter: 2010, waermebedarf: 100, temperatureScale: 3 },
    { combinedType: 'WGB2010B', baualter: 2010, waermebedarf: 70, temperatureScale: 4 },
    { combinedType: 'WGB2025A', baualter: 2025, waermebedarf: 55, temperatureScale: 5 },
    { combinedType: 'WGB2025B', baualter: 2025, waermebedarf: 40, temperatureScale: 6 },
    { combinedType: 'WGB2025C', baualter: 2025, waermebedarf: 25, temperatureScale: 7 },
    //
    { combinedType: 'SKG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'SKG2010A', baualter: 2010, waermebedarf: 70, temperatureScale: 2 },
    { combinedType: 'SKG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'SKG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'HG1945', baualter: 1945, waermebedarf: 150, temperatureScale: 0 },
    { combinedType: 'HG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'HG2025A', baualter: 2025, waermebedarf: 35, temperatureScale: 4 },
    { combinedType: 'HG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'BG1945', baualter: 1945, waermebedarf: 140, temperatureScale: 0 },
    { combinedType: 'BG2010A', baualter: 2010, waermebedarf: 80, temperatureScale: 2 },
    { combinedType: 'BG2025A', baualter: 2025, waermebedarf: 40, temperatureScale: 4 },
    { combinedType: 'BG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },
    //
    { combinedType: 'GG1945', baualter: 1945, waermebedarf: 170, temperatureScale: 0 },
    { combinedType: 'GG2010A', baualter: 2010, waermebedarf: 120, temperatureScale: 2 },
    { combinedType: 'GG2025A', baualter: 2025, waermebedarf: 80, temperatureScale: 4 },
    { combinedType: 'GG2025B', baualter: 2025, waermebedarf: 0, temperatureScale: 7 },


];

export const spitzenLastOptions = [
    'Holzpellets',
    'Gaskessel',
    'Stromdirekt',
    'Keine',
]

export const netzSonderPotenziale = [
    'Abwärme',
    'Kraft-Wärme-Kopplung',
    'Keine'
]


export const einzel_loesung_options = [
    {
        solutionClass: 'Wärmepumpe',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Grundwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
        ]
    },
    {
        solutionClass: 'Verbrennung',
        options: [
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },
        ],
    },
]




export const solution_builder_options = [
    {
        solutionClass: 'Fern',
        options: [
            {
                heatSource: 'Fernwärme Anbieter',
                spitzenLastBedarf: false,
            }
        ]
    },
    {
        solutionClass: 'Netz',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gewässer Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Abwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Geothermie',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },

        ]
    },
    {
        solutionClass: 'Objekt',
        options: [
            {
                heatSource: 'Luft Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Grundwasser Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Erdsonden Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Flächenkollektor Wärmepumpe',
                spitzenLastBedarf: true,
            },
            {
                heatSource: 'Holzpellets',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Gaskessel',
                spitzenLastBedarf: false,
            },
            {
                heatSource: 'Stromdirekt',
                spitzenLastBedarf: false,
            },
        ]
    }
]

export const auslegungstemp = -10;
export const vollnutzungstunden_wohn = 2100;

// https://www.gesetze-im-internet.de/wpg/BJNR18A0B0023.html 
// WE LOVE LEGAL TEXTS
export const wpg_bestehendes_netz =[
    {year: 2025, percent_renewable: 0},
    {year: 2030, percent_renewable: 30},
    {year: 2040, percent_renewable: 80},
    {year: 2045, percent_renewable: 100}
  ]
  
export const wpg_neues_netz =[
    {year: 2025, percent_renewable: 65},
    {year: 2030, percent_renewable: 65},
    {year: 2040, percent_renewable: 80},
    {year: 2045, percent_renewable: 100}
  ]  

export const wpg_event_faktor =  0.5;
  
export const building_map = [
    {
        name: 'EFH',
        leistungsbereich_l: '3',
        leistungsbereich_h: '20',
        beispielleistung: '12',
    },
    {
        name: 'MFH',
        leistungsbereich_l: '30',
        leistungsbereich_h: '150',
        beispielleistung: '60',
    },
    {
        name: 'GMFH',
        leistungsbereich_l: '200',
        leistungsbereich_h: '2000',
        beispielleistung: '1000',
    },
    {
        name: 'Netz',
        leistungsbereich_l: '5000',
        leistungsbereich_h: '100000',
        beispielleistung: '50000',
    },
]
