
import { getVerbrennungEnergieBedarfObjekt, getVerbrennungCo2 } from './Verbrennung';
import { chartYears } from '../../IPKWConsts';
import { getWPAndSpitzenlastCo2 } from './Waermepumpe';

export function getEinzelCo2(ipkwQuartierData, scenarioConfig, einzelConfig) {
    const { solutionClass, heatSource, spitzenLast, spitzenLastPercentage = 0 } = einzelConfig;
    const gesamtVerbrauch = ipkwQuartierData.quartierData.gesamtverbrauch;
    let dataNullszenario = [];

    if (solutionClass === 'Verbrennung') {
        const bedarfTimeline = getVerbrennungEnergieBedarfObjekt(gesamtVerbrauch, scenarioConfig, heatSource, chartYears);
        dataNullszenario = getVerbrennungCo2(bedarfTimeline, heatSource);

    } else if (solutionClass === 'Wärmepumpe') {
        dataNullszenario = getWPAndSpitzenlastCo2(gesamtVerbrauch, scenarioConfig, heatSource, spitzenLast, spitzenLastPercentage);

    } else if (solutionClass) {
        console.error('Solution class not supported in getEinzelCo2:', solutionClass);
    } else {
        console.error('Solution class not defined in getEinzelCo2:', solutionClass);
    }

    return dataNullszenario;
}
