
import { building_map, vollnutzungstunden_wohn } from '../../components/ipkw/IPKWConsts';
import { leistungToEnergy } from '../ipkw/dashboard/charts/CalculatorUtil';

export const getLeistungStyle = (leistung, selectedBuilding) => {
    const lower = building_map.find((building) => building.name === selectedBuilding).leistungsbereich_l;
    const upper = building_map.find((building) => building.name === selectedBuilding).leistungsbereich_h;
    if (!(leistung >= lower && leistung <= upper)) {
        return { backgroundColor: 'var(--gray90)' };
    }
}


export const getCalculationHelper = (selectedBuilding, heatpumpData) => {

    const building = building_map.find((building) => building.name === selectedBuilding);
    const exampleLeistung = building.beispielleistung;

    const waermebedarf = leistungToEnergy(exampleLeistung);

    // Find the closest matching heat pump data based on the building's leistung
    const matchingHeatpump = heatpumpData.reduce((prev, curr) => {
        return Math.abs(curr.leistung - exampleLeistung) < Math.abs(prev.leistung - exampleLeistung) ? curr : prev;
    });

    // Extract estimated costs from the matching heat pump data
    const estimatedCostsAnlage = matchingHeatpump.investKostenGesamtAnlage ? matchingHeatpump.investKostenGesamtAnlage : "Unbekannt";
    const geringinvestiveKosten = matchingHeatpump.geringinvestiveKosten ? matchingHeatpump.geringinvestiveKosten : "Unbekannt";
    const pufferspeicherKosten = matchingHeatpump.pufferspeicherKosten  ? matchingHeatpump.pufferspeicherKosten : "Unbekannt";
    const investKostenGesamt = matchingHeatpump.investKostenGesamt  ? matchingHeatpump.investKostenGesamt : "Unbekannt";

    return (
        <div>
            <h3>Beispielkosten für ein {selectedBuilding} mit {exampleLeistung} kW Wärmeleistungsbedarf & {vollnutzungstunden_wohn} Vollnutzungsstunden pro Jahr</h3>
            <div><strong>Wärmebedarf:</strong> <br />{waermebedarf} kWh/Jahr</div><br />
            <div><strong>Geschätzte Kosten Anlage:</strong> <br />{estimatedCostsAnlage.toLocaleString()} €</div><br />
            <div><strong>Geringinvestive Kosten:</strong><br /> {geringinvestiveKosten.toLocaleString()} €</div><br />
            <div><strong>Pufferspeicher Kosten:</strong><br /> {pufferspeicherKosten.toLocaleString()} €</div><br />
            <div><strong>Investitionskosten Gesamt:</strong><br /> {investKostenGesamt.toLocaleString()} €</div><br />
        </div>
    );
};