import React, { memo } from 'react';
import { eekMapping } from '../IPKWConsts';

function asMWh(value) {
  return (value / 1000).toFixed(1);
}

const HoverCard = memo(({ mapHoverState, setMapHoverState, mousePosition }) => {
  if (!mapHoverState.hoverEnabled) return null;
  if (!mapHoverState.hoverData) return null;
  if (!mapHoverState.hoverData.nutzflaeche) return null;

  const eek = eekMapping.find(
    (eek) => eek.sanierungstand === mapHoverState.hoverData.sanierungsstand
  )?.eek;



  const renderContent = () => (
    <div
      className="hover-card"
      style={{
        left: `${mapHoverState.x || mousePosition.x}px`,
        top: `${mapHoverState.y || mousePosition.y}px`,
      }}
    >
      <div className="attribute">
        <div className="attribute-title">
          <strong>Gebäude Typ:</strong>
        </div>
        <div className="attribute-content">
          {mapHoverState.hoverData.gebaeudetyp}
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Gebäude Nutzung:</strong>
        </div>
        <div className="attribute-content">
          {mapHoverState.hoverData.nutzung}
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Nutzfläche:</strong>
        </div>
        <div className="attribute-content">
          {mapHoverState.hoverData.nutzflaeche.toFixed(1)} m²
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Stockwerke:</strong>
        </div>
        <div className="attribute-content">
          {mapHoverState.hoverData.stockwerke}
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Spezifischer Raum & Wasserwärmebedarf:</strong>
        </div>
        <div className="attribute-content">
          {mapHoverState.hoverData.rw_ww_spez.toFixed(1)} kWh/m²a
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Jahres Raum & Wasserwärmebedarf:</strong>
        </div>
        <div className="attribute-content">
          {asMWh( mapHoverState.hoverData.rw_ww)} MWh/a
        </div>
      </div>
      <div className="attribute">
        <div className="attribute-title">
          <strong>Sanierungsstand (A+ bis E):</strong>
        </div>
        <div className="attribute-content">{eek}</div>
      </div>
    </div>
  );

  if (
    !mapHoverState.currentHoverID ||
    mapHoverState.currentHoverID !== mapHoverState.hoverData.fest_id
  ) {
    setMapHoverState((prevState) => ({
      ...prevState,
      currentHoverID: mapHoverState.hoverData.fest_id,
      x: mousePosition.x,
      y: mousePosition.y,
    }));
    return renderContent();
  }

  if (mapHoverState.currentHoverID === mapHoverState.hoverData.fest_id) {
    return renderContent();
  }

  return null;
});

export default HoverCard;
