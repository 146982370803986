const legendOptions = [
    { name: 'Spezifischer Wärmebedarf', legendName: 'specificLegend' },
    { name: 'Absoluter Wärmebedarf', legendName: 'absoluteLegend' },
    // 'Spezifischer Wärmebedarf',
    // 'Nutzfläche',
    // 'Energieeffizienzklasse',
    // 'Gebäudetyp',
];

const buildingTypesColorMap = [
    { type: 'RH', age: '1900', color: 'var(--temperature-scale-6)' },
    { type: 'RH', age: '1945', color: 'var(--temperature-scale-3)' },
    { type: 'RH', age: '1960', color: 'var(--temperature-scale-1)' },
    { type: 'RH', age: '1970', color: 'var(--temperature-scale-3)' },
    { type: 'RH', age: '1980', color: 'var(--temperature-scale-4)' },
    { type: 'RH', age: '1985', color: 'var(--temperature-scale-6)' },
    { type: 'RH', age: '2015', color: 'var(--temperature-scale-7)' },
    { type: 'RH', age: '2015+', color: 'var(--temperature-scale-8)' },
    { type: 'EFH', age: '1900', color: 'var(--temperature-scale-6)' },
    { type: 'EFH', age: '1945', color: 'var(--temperature-scale-3)' },
    { type: 'EFH', age: '1960', color: 'var(--temperature-scale-1)' },
    { type: 'EFH', age: '1970', color: 'var(--temperature-scale-3)' },
    { type: 'EFH', age: '1980', color: 'var(--temperature-scale-4)' },
    { type: 'EFH', age: '1985', color: 'var(--temperature-scale-6)' },
    { type: 'EFH', age: '2015', color: 'var(--temperature-scale-7)' },
    { type: 'EFH', age: '2015+', color: 'var(--temperature-scale-8)' },
    { type: 'MFH', age: '1900', color: 'var(--temperature-scale-6)' },
    { type: 'MFH', age: '1945', color: 'var(--temperature-scale-3)' },
    { type: 'MFH', age: '1960', color: 'var(--temperature-scale-1)' },
    { type: 'MFH', age: '1970', color: 'var(--temperature-scale-3)' },
    { type: 'MFH', age: '1980', color: 'var(--temperature-scale-4)' },
    { type: 'MFH', age: '1985', color: 'var(--temperature-scale-6)' },
    { type: 'MFH', age: '2015', color: 'var(--temperature-scale-7)' },
    { type: 'MFH', age: '2015+', color: 'var(--temperature-scale-8)' },
    { type: 'GMFH', age: '1900', color: 'var(--temperature-scale-6)' },
    { type: 'GMFH', age: '1945', color: 'var(--temperature-scale-3)' },
    { type: 'GMFH', age: '1960', color: 'var(--temperature-scale-1)' },
    { type: 'GMFH', age: '1970', color: 'var(--temperature-scale-3)' },
    { type: 'GMFH', age: '1980', color: 'var(--temperature-scale-4)' },
    { type: 'GMFH', age: '1985', color: 'var(--temperature-scale-6)' },
    { type: 'GMFH', age: '2015', color: 'var(--temperature-scale-7)' },
    { type: 'GMFH', age: '2015+', color: 'var(--temperature-scale-8)' },
    { type: 'NWG', age: '1900', color: 'var(--temperature-scale-6)' },
    { type: 'NWG', age: '1945', color: 'var(--temperature-scale-3)' },
    { type: 'NWG', age: '1960', color: 'var(--temperature-scale-1)' },
    { type: 'NWG', age: '1970', color: 'var(--temperature-scale-3)' },
    { type: 'NWG', age: '1980', color: 'var(--temperature-scale-4)' },
    { type: 'NWG', age: '1985', color: 'var(--temperature-scale-6)' },
    { type: 'NWG', age: '2015', color: 'var(--temperature-scale-7)' },
    { type: 'NWG', age: '2015+', color: 'var(--temperature-scale-8)' },

]

const specificLegend = [
    { text: '30', number: 30, EEK: 'A+', temperatureCSSIndex: 8, unit: 'kWh/m²a' },
    { text: '50', number: 50, EEK: 'A', temperatureCSSIndex: 7, unit: 'kWh/m²a' },
    { text: '75', number: 75, EEK: 'B', temperatureCSSIndex: 6, unit: 'kWh/m²a' },
    { text: '100', number: 100, EEK: 'C', temperatureCSSIndex: 5, unit: 'kWh/m²a' },
    { text: '130', number: 130, EEK: 'D', temperatureCSSIndex: 4, unit: 'kWh/m²a' },
    { text: '160', number: 160, EEK: 'E', temperatureCSSIndex: 3, unit: 'kWh/m²a' },
    { text: '200', number: 200, EEK: 'F', temperatureCSSIndex: 2, unit: 'kWh/m²a' },
    { text: '250', number: 250, EEK: 'G', temperatureCSSIndex: 1, unit: 'kWh/m²a' },
    { text: '250+', number: 250, EEK: 'H', temperatureCSSIndex: 0, unit: 'kWh/m²a' },
]

const absoluteLegend = [
    { text: '1000', number: 1000, temperatureCSSIndex: 8, unit: 'kWh/a' },
    { text: '3000', number: 3000, temperatureCSSIndex: 7, unit: 'kWh/a' },
    { text: '6000', number: 6000, temperatureCSSIndex: 6, unit: 'kWh/a' },
    { text: '10000', number: 10000, temperatureCSSIndex: 5, unit: 'kWh/a' },
    { text: '30000', number: 30000, temperatureCSSIndex: 4, unit: 'kWh/a' },
    { text: '60000', number: 60000, temperatureCSSIndex: 3, unit: 'kWh/a' },
    { text: '100000', number: 100000, temperatureCSSIndex: 2, unit: 'kWh/a' },
    { text: '100000', number: 100000, temperatureCSSIndex: 1, unit: 'kWh/a' },
    { text: '100000+', number: 100000, temperatureCSSIndex: 0, unit: 'kWh/a' },
]

const wlAbsoluteLegend = [
    { text: '350000', number: 350000, temperatureCSSIndex: 8, unit: 'kWh/ma' },
    { text: '700000', number: 700000, temperatureCSSIndex: 6, unit: 'kWh/ma' },
    { text: '1050000', number: 1050000, temperatureCSSIndex: 4, unit: 'kWh/ma' },
    { text: '1400000', number: 1400000, temperatureCSSIndex: 2, unit: 'kWh/ma' },
]
function getMapLegendStyle(selectedLegend, feature, zoom, sidebarHoverState) {
    const weight = zoom < 17 ? 0 : 0.5;


    switch (selectedLegend) {
        case "specificLegend":
            return selectLegendWBSpz(feature, weight, sidebarHoverState);
        case 'absoluteLegend':
            return selectLegendRW_WW(feature, weight, sidebarHoverState);
        default:
            return null;
    }

}
function getMapLegendStyleWL( feature, zoom) {
    const weight = zoom < 17 ? 2 : 4;

    return selectLegendWL(feature,weight );

}


function selectLegendWL(feature, weight) {
    
    const wld = feature.properties.waermedichte;

    const color = 'var(--black)';

    const commonProperties = { fillOpacity: 0.8, color: color, weight: weight, zIndex: 5000 };
    if (wld >= wlAbsoluteLegend[wlAbsoluteLegend.length - 1].number) {
        return { ...commonProperties, fillColor: `var(--temperature-scale-0)` };
    }
    for (let i = 0; i < wlAbsoluteLegend.length; i++) {
        if (wld < wlAbsoluteLegend[i].number) {
            return { ...commonProperties, fillColor: `var(--temperature-scale-${specificLegend[i].temperatureCSSIndex})` };
        }
    }
}

function selectLegendWBSpz(feature, weight, sidebarHoverState) {
    
    const WBSpzValue = feature.properties.rw_ww_spez;
    const typeStr = feature.properties.gebaeudetyp.split('_');
    const type = typeStr[0];
    const age = typeStr[1];
    
    
    
    const color = sidebarHoverState.buildingHover.year === age && sidebarHoverState.buildingHover.type === type ? 'var(--colora2)' : 'var(--black)';
    const commonProperties = { fillOpacity: 0.8, color: color, weight: weight, zIndex: 5000 };
    
    if (sidebarHoverState.buildingHover.year === age && sidebarHoverState.buildingHover.type === type){

        return { ...commonProperties, fillColor: color };
    }
    
    if (WBSpzValue >= specificLegend[specificLegend.length - 1].number) {
        return { ...commonProperties, fillColor: `var(--temperature-scale-0)` };
    }
    for (let i = 0; i < specificLegend.length; i++) {
        if (WBSpzValue < specificLegend[i].number) {
            return { ...commonProperties, fillColor: `var(--temperature-scale-${specificLegend[i].temperatureCSSIndex})` };
        }
    }
}


function selectLegendRW_WW(feature, weight, sidebarHoverState) {
    const RW_WW = feature.properties.rw_ww;
    const commonProperties = { fillOpacity: 0.8, color: 'black', weight: weight, zIndex: 5000 };
    if (RW_WW >= absoluteLegend[absoluteLegend.length - 1].number) {
        return { ...commonProperties, fillColor: `var(--temperature-scale-0)` };
    }
    for (let i = 0; i < absoluteLegend.length; i++) {
        if (RW_WW < absoluteLegend[i].number) {
            return { ...commonProperties, fillColor: `var(--temperature-scale-${absoluteLegend[i].temperatureCSSIndex})` };
        }
    }
}

export { legendOptions, absoluteLegend, specificLegend, buildingTypesColorMap, getMapLegendStyle, getMapLegendStyleWL };