import React, { useEffect, useState} from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { useDashboardContext } from '../../../../context/DashboardContext';
import { getSolutionBuilderCo2 } from './SolutionBuilderCo2Calculator';
import { getEinzelCo2 } from './SingleCo2Calculator';
import { getNetzCo2 } from './NetzCo2Calculator';


function getAllCo2Data(ipkwQuartierData, scenarioConfig, einzelConfig, currentWlGeoJSONData, solutionBuilderData) {
    const years = [2025, 2030, 2035, 2040, 2045];
    let data = years.map(year => ({ year }));
  
    const einzelData = getEinzelCo2(ipkwQuartierData, scenarioConfig, einzelConfig);
    const customSolutionData = getSolutionBuilderCo2(
        ipkwQuartierData,
        scenarioConfig,
        einzelConfig,
        currentWlGeoJSONData,
        solutionBuilderData
      );
    const fwData = getNetzCo2(ipkwQuartierData, scenarioConfig, currentWlGeoJSONData, solutionBuilderData.scenarios);


    einzelData.forEach((item, idx) => {
      data[idx]["Einzellösung"] = item.emission;
    });
  
    customSolutionData.forEach((item, idx) => {
      data[idx]["Eigene Wärmelösung"] = item.emission;
    });
  
    fwData.forEach((item, idx) => {
      data[idx]["Fernwärmelösung"] = item.emission;
    });
    return data;
  }
  

const Co2Chart = () => {
    const { ipkwQuartierData, currentWlGeoJSONData, scenarioConfig, einzelConfig, solutionBuilderData} = useDashboardContext();
    const [co2Data, setCo2Data] = useState(null);
    
    const maxDomain = ipkwQuartierData.quartierData.gesamtverbrauch? Math.ceil(ipkwQuartierData.quartierData.gesamtverbrauch / 2.5e6) * 1e6 : 5e6;
    
    useEffect(() => {
        setCo2Data(getAllCo2Data(ipkwQuartierData,scenarioConfig, einzelConfig,currentWlGeoJSONData,solutionBuilderData));
    }, [ipkwQuartierData, scenarioConfig, einzelConfig,currentWlGeoJSONData,solutionBuilderData]);
    

    const options = ['Einzellösung', 'Eigene Wärmelösung', 'Fernwärmelösung']; 
    const colors = ['#ff3300', '#8884d8', '#82ca9d']; 
    const colorsdark = ['#B32400', '#3D3B61', '#528063']; 

    const initialLineVisibility = options.reduce(
        (acc, img) => ({ ...acc, [img]: true }),
        {}
    );
    initialLineVisibility['Eigene Wärmelösung'] = true; 
    const [lineVisibility, setLineVisibility] = useState(initialLineVisibility);

    const legendPayload = [
        ...options.map((img, index) => ({
            value: img,
            type: 'line',
            id: img,
            dataKey: img,
            color: colors[index % colors.length],
            darkColor: colorsdark[index % colors.length],
        })),
    ];

    const handleLegendClick = (dataKey) => {
        setLineVisibility((prevState) => ({
            ...prevState,
            [dataKey]: !prevState[dataKey],
        }));
    };

    const renderCustomizedLegend = (props) => {
        const { payload } = props;
        return (
            <ul
                className="legend-list"

            >
                {payload.map((entry, index) => (
                    <li
                        key={`item-${index}`}
                        onClick={() => handleLegendClick(entry.dataKey)}
                        style={{
                            cursor: 'pointer',
                            marginRight: 10,
                        }}
                    >
                        <span
                            style={{

                                color: lineVisibility[entry.dataKey]
                                    ? entry.color
                                    : entry.darkColor,                            }}
                        >
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="status-quo-charts">
            <div className="chart-container">
                <div className="chart-label">
                    GT CO<sub>2</sub>/a durch Wärmebereitstellung 
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={co2Data}>
                        <Legend content={renderCustomizedLegend} payload={legendPayload} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" />
                        <YAxis
                            tickFormatter={(value) => `${value / 1e6} GT`}
                            domain={[0, maxDomain]}
                        />
                        <Tooltip formatter={(value) => `${(value / 1e6).toFixed(4)} GT`} />

                        {options.map((img, index) =>
                            lineVisibility[img] ? (
                                <Line
                                    key={img}
                                    name={img}
                                    type="monotone"
                                    dataKey={img}
                                    stroke={colors[index % colors.length]}
                                    isAnimationActive={false} 

                                />
                            ) : null
                        )}
                        {lineVisibility['Eigene Wärmelösung'] && (
                            <Line
                                name="Eigene Wärmelösung"
                                type="monotone"
                                dataKey="solutionBuilder"
                                stroke="#000000"
                                isAnimationActive={false} 

                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Co2Chart;