import { vollnutzungstunden_wohn } from "../../IPKWConsts";

export function energyToLeistung(leistung) {
    return leistung / vollnutzungstunden_wohn;
}
export function leistungToEnergy(leistung) {
    return leistung * vollnutzungstunden_wohn;
}

export function bedarfMitSanierung(gesamtVerbrauch, sanierungsquote, sanierungsimpact, years) {
    const lowerEachYearBy = gesamtVerbrauch * sanierungsquote * sanierungsimpact;
    const startYear = years[0];
    const data = years.map(year => {
        const yearsSinceStart = year - startYear;
        const requiredBedarf = gesamtVerbrauch - (lowerEachYearBy * yearsSinceStart);
        return {
            year: year,
            requiredBedarf: requiredBedarf
        };
    });
    return data;
}


export function pickNRWTKRowByLeistung(leistung, NRWTKJson) {
    const json_contains_kw = NRWTKJson[0].leistung_th_kw !== undefined;
    if (json_contains_kw) {
        return (
            NRWTKJson.find(row => row.leistung_th_kw >= leistung) ||
            NRWTKJson[NRWTKJson.length - 1]
        );
    } else {
        return (
            NRWTKJson.find(row => row.leistung_th_mw >= leistung) ||
            NRWTKJson[NRWTKJson.length - 1]
        );
    }

}


export function getWPGRenewableFractionForYear(year, wpgData, wpg_event) {
    const sorted = [...wpgData].sort((a, b) => a.year - b.year);

    if (year <= sorted[0].year) {
        if (wpg_event) {
            return sorted[0].percent_renewable*0.5;
        }
        return sorted[0].percent_renewable;
    }

    if (year >= sorted[sorted.length - 1].year) {
        if (wpg_event) {
            return sorted[sorted.length - 1].percent_renewable*0.5;
        }
        return sorted[sorted.length - 1].percent_renewable;
    }

    for (let i = 0; i < sorted.length - 1; i++) {
        const curr = sorted[i];
        const next = sorted[i + 1];

        if (year >= curr.year && year <= next.year) {
            const rangeYears = next.year - curr.year;
            const rangeFrac = next.percent_renewable - curr.percent_renewable;
            const diffYear = year - curr.year;
            if (wpg_event) {
                return (curr.percent_renewable + (rangeFrac * diffYear) / rangeYears )*0.5;
            }
            return curr.percent_renewable + (rangeFrac * diffYear) / rangeYears;
        }
    }

    return 0;
}

