// ScenarioSelector.js
import React, { useState } from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/QuartierCard.css';

import flut from '../../../../images/flut.png';
import hack from '../../../../images/energieversorger_hack.png';
import law from '../../../../images/law.png';
import ford_ee from '../../../../images/fordEE.png';
import krieg from '../../../../images/krieg.png';
import random from '../../../../images/random.png';
import configure_icon from '../../../../images/configure_icon.png';

import { useDashboardContext } from '../../../../context/DashboardContext';
import ScenarioConfigModal from '../scenarioBuilder/ScenarioConfigModal';

const ScenarioSelector = () => {
    const { solutionBuilderData, setSolutionBuilderData } = useDashboardContext();

    const [activeScenarios, setActiveScenarios] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [showConfigModal, setShowConfigModal] = useState(false);

    const images = [
        { src: law, name: 'Wärmeplanungsgesetz Halbiert' },
        { src: flut, name: 'Hochwasser' },
        { src: hack, name: 'Energieversorger Hack' },
        { src: ford_ee, name: 'Förderung Erneuerbare Energien' },
        { src: krieg, name: 'Gaspreis Steigt' },
        { src: random, name: 'Zufall' },
    ];

    const toggleScenario = (name) => {
        if (activeScenarios.includes(name)) {
            setActiveScenarios(activeScenarios.filter((scenario) => scenario !== name));
            setSolutionBuilderData((prevSolutionBuilderData) => {
                return {
                    ...prevSolutionBuilderData,
                    scenarios: prevSolutionBuilderData.scenarios.filter((scenario) => scenario !== name),
                };
            }
            );
        } else {
            setActiveScenarios([...activeScenarios, name]);
            setSolutionBuilderData((prevSolutionBuilderData) => {
                return { ...prevSolutionBuilderData, scenarios: [...prevSolutionBuilderData.scenarios, name] };
            });

        }
    };

    const handleRandomScenario = () => {
        const randomIndex = Math.floor(Math.random() * (images.length - 1));
        toggleScenario(images[randomIndex].name);
    };

    const handleImageClick = () => {
        setShowOptions(!showOptions);
    };

    const handleConfigScenario = () => {
        setShowConfigModal(!showConfigModal);
    };

    const handleCloseModal = () => {
        setShowConfigModal(false);
    };

    return (
        <div className='scenario-selector'>
            <div className='config-header-container' onClick={handleConfigScenario}>
                <div className='configure-combo' >
                    <img src={configure_icon} alt='Configure Icon' className='configure-icon' />
                    <div className='configure-text'>Szenario Konfigurieren</div>
                </div>
            </div>

            <div className='szenario-event-row'>
                <div className='selector-title' onClick={handleImageClick}>
                    Event Karten
                </div>
                <div className='square-image-container'>
                    {showOptions && (
                        <div className='image-options-popup-left'>
                            {images.map((img, index) => (
                                <div
                                    key={index}
                                    className='option-item'
                                    onClick={() =>
                                        img.name === 'Zufall' ? handleRandomScenario() : toggleScenario(img.name)
                                    }
                                >
                                    <img
                                        src={img.src}
                                        alt={img.name}
                                        className={
                                            activeScenarios.includes(img.name) ? 'option-image active' : 'option-image'
                                        }
                                    />
                                    <div className='option-name'>{img.name}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='active-scenarios-list'>
                    {activeScenarios.map((scenario, index) => (
                        <div className='scenario-entry' key={index}>
                            {scenario}
                        </div>
                    ))}
                </div>
            </div>

            {showConfigModal && <ScenarioConfigModal onClose={handleCloseModal} />}
        </div>
    );
};

export default ScenarioSelector;
