import React, { useState, useEffect, useMemo } from 'react';
import '../../../../styles/ipkw/ConfigModal.css';
import '../../../../styles/ipkw/SolutionBuilder.css';

import { einzel_loesung_options, spitzenLastOptions } from '../../../../components/ipkw/IPKWConsts';
import { useDashboardContext } from '../../../../context/DashboardContext';

const EinzelModal = ({ onClose }) => {
  const { einzelConfig, setEinzelConfig } = useDashboardContext();

  const [requiresSpitzenLast, setRequiresSpitzenLast] = useState(false);

  const solutionClasses = einzel_loesung_options.map(option => option.solutionClass);

  const heatSourceOptions = useMemo(() => {
    return einzelConfig.solutionClass
      ? einzel_loesung_options.find(option => option.solutionClass === einzelConfig.solutionClass).options
      : [];
  }, [einzelConfig.solutionClass]);

  useEffect(() => {
    if (einzelConfig.heatSource) {
      const selectedOption = heatSourceOptions.find(
        (option) => option.heatSource === einzelConfig.heatSource
      );

      if (selectedOption) {
        setRequiresSpitzenLast(selectedOption.spitzenLastBedarf);
        if (!selectedOption.spitzenLastBedarf) {
          setEinzelConfig(prevConfig => ({ ...prevConfig, spitzenLast: '', spitzenLastPercentage: 0 }));
        }
      } else {
        setRequiresSpitzenLast(false);
        setEinzelConfig(prevConfig => ({ ...prevConfig, spitzenLast: '', spitzenLastPercentage: 0 }));
      }
    } else {
      setRequiresSpitzenLast(false);
      setEinzelConfig(prevConfig => ({ ...prevConfig, spitzenLast: '', spitzenLastPercentage: 0 }));
    }
  }, [einzelConfig.heatSource, setEinzelConfig, heatSourceOptions]);

  const handleSolutionClassChange = (e) => {
    const selectedClass = e.target.value;
    setEinzelConfig({
      solutionClass: selectedClass,
      heatSource: '',
      spitzenLast: '',
      spitzenLastPercentage: 0,
    });
  };

  const handleHeatSourceChange = (e) => {
    const selectedSource = e.target.value;
    setEinzelConfig(prevConfig => ({
      ...prevConfig,
      heatSource: selectedSource,


    }));
  };

  const handleSpitzenLastChange = (e) => {
    const selectedSpitzenLast = e.target.value;
    setEinzelConfig(prevConfig => ({
      ...prevConfig,
      spitzenLast: selectedSpitzenLast,
    }));
  };

  const handleSpitzenLastPercentageChange = (e) => {
    let val = parseInt(e.target.value, 10);
    if (val > 30) val = 30;
    setEinzelConfig(prevConfig => ({
      ...prevConfig,
      spitzenLastPercentage: val,
    }));
  };

  return (
    <div className='modal-overlay-right'>
      <div className='config-header'>
        <div className='tab-buttons'>
          <div className='tab-header'>Einzellösung konfigurieren</div>
        </div>
        <button className='close-button' onClick={onClose}>
          X
        </button>
      </div>
      <div className='modal-content'>
        <div className='select-group'>
          <label htmlFor='solution-class-select'>Energieträger:</label>
          <select
            id='solution-class-select'
            value={einzelConfig.solutionClass}
            onChange={handleSolutionClassChange}
          >
            <option value='' disabled>
              -- Wählen Sie einen Energieträger --
            </option>
            {solutionClasses.map((solutionClass, index) => (
              <option key={index} value={solutionClass}>
                {solutionClass}
              </option>
            ))}
          </select>
        </div>

        {einzelConfig.solutionClass && (
          <div className='select-group'>
            <label htmlFor='heat-source-select'>Hauptenergiequelle:</label>
            <select
              id='heat-source-select'
              value={einzelConfig.heatSource}
              onChange={handleHeatSourceChange}
            >
              <option value='' disabled>
                -- Wählen Sie eine Hauptenergiequelle --
              </option>
              {heatSourceOptions.map((option, index) => (
                <option key={index} value={option.heatSource}>
                  {option.heatSource}
                </option>
              ))}
            </select>
          </div>
        )}

        {requiresSpitzenLast && (
          <div className='select-group'>
            <label htmlFor='spitzenlast-select'>Spitzenlast:</label>
            <select
              id='spitzenlast-select'
              value={einzelConfig.spitzenLast}
              onChange={handleSpitzenLastChange}
            >
              <option value='' disabled>
                -- Wählen Sie eine Spitzenlastquelle --
              </option>
              {spitzenLastOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>

            {einzelConfig.spitzenLast && einzelConfig.spitzenLast !== 'Keine' && (
              <div className='percentage-slider-container'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  value={einzelConfig.spitzenLastPercentage || 0}
                  onChange={handleSpitzenLastPercentageChange}
                  className='percentage-slider'
                />
                <span>{einzelConfig.spitzenLastPercentage || 0}%</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EinzelModal;
