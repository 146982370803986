import { getEinzelCo2 } from './SingleCo2Calculator';
import { getVerbrennungEnergieBedarfObjekt, getVerbrennungCo2 } from './Verbrennung';
import { getWPEnergieBedarfObjekt, getWPCo2, getWPAndSpitzenlastCo2 } from './Waermepumpe';
import { getNetzCo2 } from './NetzCo2Calculator';
import { technikkatalog_jahre, technikkatalog_emissionen, verteilnetze, transportleitungen } from '../../NRWTKConsts';
import { bedarfMitSanierung, pickNRWTKRowByLeistung } from './CalculatorUtil';
import { vollnutzungstunden_wohn, chartYears } from '../../IPKWConsts';

function detectSolutionClass(heatSource) {
  if (heatSource && heatSource.toLowerCase().includes('wärmepumpe')) {
    return 'Wärmepumpe';
  }
  return 'Verbrennung';
}

export function getSolutionBuilderCo2(
  ipkwQuartierData,
  scenarioConfig,
  einzelConfig,
  currentWlGeoJSONData,
  solutionBuilderData
) {
  const combined = chartYears.map((year) => ({ year, emission: 0 }));
  if (!solutionBuilderData || solutionBuilderData.length === 0) {
    return combined;
  }

  const totalDemand = ipkwQuartierData.quartierData.gesamtverbrauch;

  solutionBuilderData.solutions.forEach((solution) => {
    const fraction = solution.coverage / 100;
    if (fraction <= 0) return;

    let partialDemand = totalDemand * fraction;

    if (
      solution.type === 'Netz' &&
      solution.sonderPotenzial &&
      solution.sonderPotenzial !== 'Keine' &&
      solution.sonderPotenzialPercentage > 0
    ) {
      partialDemand *= 1 - solution.sonderPotenzialPercentage / 100;
    }

    const partialQuartierData = {
      ...ipkwQuartierData,
      quartierData: {
        ...ipkwQuartierData.quartierData,
        gesamtverbrauch: partialDemand,
      },
    };

    if (solution.type === 'Fern') {
      const netzResults = getNetzCo2(partialQuartierData, scenarioConfig, currentWlGeoJSONData, solutionBuilderData.scenarios);
      netzResults.forEach((nr, idx) => {
        combined[idx].emission += nr.emission;
      });
    } else if (solution.type === 'Netz') {
      const netzResults = getDistrictNetworkCo2(
        partialQuartierData,
        scenarioConfig,
        currentWlGeoJSONData,
        solution.heatSource,
        solution.spitzenLast,
        solution.spitzenLastPercentage
      );
      netzResults.forEach((nr, idx) => {
        combined[idx].emission += nr.emission;
      });
    } else if (solution.type === 'Objekt') {
      const singleClass = detectSolutionClass(solution.heatSource);
      const einzelResults = getEinzelCo2(partialQuartierData, scenarioConfig, {
        solutionClass: singleClass,
        heatSource: solution.heatSource,
        spitzenLast: solution.spitzenLast,
        spitzenLastPercentage: solution.spitzenLastPercentage,
      });
      einzelResults.forEach((er, idx) => {
        combined[idx].emission += er.emission;
      });
    }
  });
  return combined;
}

export function getDistrictNetworkCo2(
  ipkwQuartierData,
  scenarioConfig,
  currentWlGeoJSONData,
  heatSource,
  spitzenLast,
  spitzenLastPercentage
) {
  if (
    !ipkwQuartierData ||
    !ipkwQuartierData.quartierData ||
    !ipkwQuartierData.buildingData ||
    !currentWlGeoJSONData
  ) {
    console.error('Missing data for district network calculation.');
    return [];
  }

  const baseDemand = ipkwQuartierData.quartierData.gesamtverbrauch;

  const bedarfTimeline = bedarfMitSanierung(
    baseDemand,
    scenarioConfig.sanierungsQuoteYear,
    scenarioConfig.sanierungsEfficiency,
    chartYears
  );

  const peakLoadkW = (baseDemand / vollnutzungstunden_wohn) * 1000;
  const peakLoadMW = peakLoadkW / 1000;

  const totalLengthMeters = currentWlGeoJSONData.reduce(
    (sum, feat) => sum + (feat?.properties?.strassenlaenge || 0),
    0
  );
  const totalLengthKM = totalLengthMeters / 1000;

  const netzRow = pickNRWTKRowByLeistung(peakLoadkW, verteilnetze);
  const stationLossPct = netzRow.waermeverlusteUebergabestationen;
  const pumpPct = netzRow.verbrauchHilfsenergie;

  const transportRow = pickNRWTKRowByLeistung(peakLoadMW, transportleitungen);
  const transportLossPctPerKM = transportRow.waermeverlusteLeitungen;

  const stromData = technikkatalog_emissionen.find(e => e.energietraeger === 'Strom-Mix-D');
  if (!stromData) {
    console.error('Strom-Mix-D data not found');
    return [];
  }

  const yearsAvailable = technikkatalog_jahre;
  const stromEF = {};
  chartYears.forEach((y) => {
    const idx = yearsAvailable.indexOf(y);
    stromEF[y] =
      idx !== -1
        ? stromData.emissionen[idx] / 1000
        : stromData.emissionen[stromData.emissionen.length - 1] / 1000;
  });

  const result = bedarfTimeline.map((item, index) => {
    const { year, requiredBedarf } = item;

    const stationLossMWh = (requiredBedarf * stationLossPct) / 100;
    const totalTransportLossPct = (transportLossPctPerKM / 100) * totalLengthKM;
    const transportLossMWh = requiredBedarf * totalTransportLossPct;

    const totalHeatNeeded = requiredBedarf + stationLossMWh + transportLossMWh;


    let localGeneratorCO2 = 0;
    if (heatSource && heatSource.toLowerCase().includes('wärmepumpe')) {

      const wpBedarfTimeline = getWPAndSpitzenlastCo2(totalHeatNeeded, scenarioConfig, heatSource, spitzenLast, spitzenLastPercentage);
      localGeneratorCO2 = wpBedarfTimeline?.[index]?.emission || 0;
    } else {
      const verbrennungsTimeline = getVerbrennungEnergieBedarfObjekt(totalHeatNeeded, scenarioConfig, heatSource, chartYears);
      const verbEmissions = getVerbrennungCo2(verbrennungsTimeline, heatSource);
      localGeneratorCO2 = verbEmissions?.[index]?.emission || 0;
    }


    const pumpMWh = (totalHeatNeeded * pumpPct) / 100;
    const pumpCO2 = pumpMWh * stromEF[year];
    
    return {
      year,
      emission: localGeneratorCO2 + pumpCO2,
    };
  });
  return result;
}
