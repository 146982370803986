import React from 'react';
import NavButton from './NavButton';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../styles/Wiki.css';
import '../styles/NavBar.css';
import { useDashboardContext } from '../context/DashboardContext';
import { luetticherData, preuswaldData, ziegelweiherData } from '../components/ipkw/PresetDistricts';

const NavBanner = () => {
  const { quartierSelection, setSidebarState, setMapState, setQuartierSelection } = useDashboardContext();

  const location = useLocation();
  const navigate = useNavigate();
  const options = [
    { value: 'geg', label: 'GEG' },
    { value: 'waermepumpen', label: 'Wärmepumpen' },
    { value: 'sanieren', label: 'Sanieren' },
    { value: 'szenarien', label: 'Szenarien' }
  ];


  const handleChange = (selectedOption) => {
    navigate(`${'/wiki/' + selectedOption.value}`);
  };
  const urlSplit = location.pathname.split('/').filter(Boolean);

  const urlMapping = {
    'about': 'About',
    'wiki': 'Wiki',
    'geg': 'GEG',
    'waermepumpen': 'Wärmepumpen',
    'sanieren': 'Sanieren',
    'szenarien': 'Szenarien',
    'ipkw': 'IPKW',
    'quartier': 'Quartier',
    'dashboard': 'Dashboard',
  };

  const loadQuartierBySidebar = (newSelection) => {
    const { quartierSelection } = newSelection;

    if (quartierSelection === 'load') {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.json';
      input.click();
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result);
            if (json.name && json.quartier && json.geometry) {
              setMapState(prevState => ({ ...prevState, geometry: json.geometry }));
            } else {
              alert('Invalid JSON');
            }
          } catch (error) {
            alert('Invalid JSON');
          }
        };
        reader.readAsText(file);
      };
    } else {
      switch (quartierSelection) {
        case 'luetticher':
          setMapState(prevState => ({ ...prevState, geometry: luetticherData.geometry }));
          break;
        case 'preuswald':
          setMapState(prevState => ({ ...prevState, geometry: preuswaldData.geometry }));
          break;
        case 'ziegelweiher':
          setMapState(prevState => ({ ...prevState, geometry: ziegelweiherData.geometry }));
          break;
        default:
          setSidebarState('none');
          break;
      }
    }
    setQuartierSelection(prevState => ({ ...prevState, quartierSelection }));

  };

  const renderNavBanner = () => {
    // Home Navigation
    if (urlSplit.length === 0) {
      return (
        <>&gt;
          <NavButton className="option" to="/about" outline>About</NavButton>
          <NavButton className="option" to="/wiki" outline>Wiki</NavButton>
          <NavButton className="option" to="/dashboard" outline>Dashboard</NavButton>
        </>
      );
    }
    if (urlSplit.length === 1) {
      if (urlSplit[0] === 'about') {
        return (
          <>
            &gt;
            <NavButton className={"selected"} to="/about" outline>About</NavButton>
            <NavButton className="option" to="/wiki" outline>Wiki</NavButton>
            <NavButton className="option" to="/dashboard" outline>Dashboard</NavButton>
          </>
        );
      } else if (urlSplit[0] === 'wiki') {
        return (
          <>

            &gt;
            <NavButton className="option" to="/about" outline>About</NavButton>
            <NavButton className={"selected"} to="/wiki" outline>Wiki</NavButton>
            <NavButton className="option" to="/dashboard" outline>Dashboard</NavButton>
            &gt;
            <Select className='wiki-select' options={options} onChange={handleChange} placeholder="Search Wiki Pages..." />

          </>
        );
      }

      else {
        return (
          <>
            &gt;
            <NavButton className="option" to="/about" outline>About</NavButton>
            <NavButton className="option" to="/wiki" outline>Wiki</NavButton>
            <NavButton className="selected" to="/dashboard" outline>Dashboard</NavButton>
            &gt;<NavButton className="option" to="/dashboard/map" outline>Map</NavButton>
            <NavButton className="option" to="/dashboard/quartier" outline>Quartier</NavButton>
          </>
        );
      }

    }

    if (urlSplit.length === 2) {
      if (urlSplit[0] === 'dashboard') {
        return (
          <>&gt;
            <NavButton className="option" to="/about" outline>About</NavButton>
            <NavButton className="option" to="/wiki" outline>Wiki</NavButton>
            <NavButton to="/dashboard" outline>Dashboard</NavButton>

            &gt;<NavButton className={urlSplit[1] !== "map" ? "option" : "selected"} to="/dashboard/map" outline>Map</NavButton>
            <NavButton className={urlSplit[1] === "map" ? "option" : "selected"} to="/dashboard/quartier" outline>Quartier</NavButton>
          </>
        );
      } else {
        const wikiSub = '/' + urlSplit[0] + '/' + urlSplit[1];
        return (
          <>
            &gt;
            <NavButton className="option" to="/about" outline>About</NavButton>
            <NavButton to="/wiki" outline>Wiki</NavButton>
            <NavButton className="option" to="/dashboard" outline>Dashboard</NavButton>
            &gt;
            <Select className='wiki-select' options={options} onChange={handleChange} placeholder="Search Wiki Pages..." />
            &gt;
            <NavButton className="selected" to={wikiSub} outline>{urlMapping[urlSplit[1]]}</NavButton>


          </>
        );
      }
    }
  }


  return (
    <div className='nav-container'>

      <div className='nav-left'>
        <NavButton className={urlSplit.length === 0 ? "selected" : null} to="/">🏠</NavButton>
        {renderNavBanner()}
      </div>

      { urlSplit.length > 0 && urlSplit[0] === 'dashboard' &&
        <div className='nav-right'>
        <div className='quartier-selector-container'>
          <div className='info-label'>Quartier:</div>
          <select
            value={quartierSelection.quartierSelection}
            onChange={(e) => loadQuartierBySidebar({ quartierSelection: e.target.value })}
            className="quartier-selector">
            {quartierSelection.modellQuartierOptions.map((option, index) => (
              <option key={index} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>}
    </div>
  );
};

export default NavBanner;
