import { technikkatalog_jahre, technikkatalog_emissionen } from '../../NRWTKConsts';
import { bedarfMitSanierung } from './CalculatorUtil';


export function getStromdirektEnergieBedarfObjekt(gesamtVerbrauch, scenarioConfig, years) {
    return bedarfMitSanierung(
        gesamtVerbrauch,
        scenarioConfig.sanierungsQuoteYear,
        scenarioConfig.sanierungsEfficiency,
        years
    );

}

export function getStromdirektCo2(bedarfTimeline) {

    const emissionData = technikkatalog_emissionen.find(
        (item) => item.energietraeger === 'Strom-Mix-D'
    );

    if (!emissionData) {
        console.error('Emission data not found for Strom Mix D:');
        return [];
    }

    const years = bedarfTimeline.map((item) => item.year);

    const emissionFactors = {};

    years.forEach((year) => {
        const index = technikkatalog_jahre.indexOf(year);
        if (index !== -1) {
            emissionFactors[year] = emissionData.emissionen[index] / 1000;
        } else {
            emissionFactors[year] =
                emissionData.emissionen[emissionData.emissionen.length - 1] / 1000;
        }
    });


    return bedarfTimeline.map((item) => {
        const emissionFactor = emissionFactors[item.year];
        const emissions = emissionFactor * item.requiredBedarf;
        return {
            year: item.year,
            emission: emissions,
        };
    });
}