import React from 'react';
import '../../../../styles/ipkw/Dashboard.css';
import '../../../../styles/ipkw/SolutionBuilder.css';
import { solution_builder_options, spitzenLastOptions, netzSonderPotenziale } from '../../IPKWConsts';

const SolutionCard = ({ solution, onSolutionFieldChange }) => {
  const {
    solutionClass,
    heatSource,
    spitzenLast,
    sonderPotenzial,
    spitzenLastPercentage,
    sonderPotenzialPercentage
  } = solution;
  
  const handleSolutionClassChange = (e) => {
    const selected = e.target.value;
    onSolutionFieldChange('solutionClass', selected);
    if (selected === 'Netz') {
      const netzOptions = solution_builder_options.find(opt => opt.solutionClass === 'Netz');
      if (netzOptions?.options?.length) {
        onSolutionFieldChange('heatSource', netzOptions.options[0].heatSource);
        if (netzOptions.options[0].spitzenLastBedarf) {
          onSolutionFieldChange('spitzenLast', 'Keine');
        } else {
          onSolutionFieldChange('spitzenLast', '');
        }
      }
      onSolutionFieldChange('sonderPotenzial', 'Keine');
    } else if (selected === 'Objekt') {
      const objektOptions = solution_builder_options.find(opt => opt.solutionClass === 'Objekt');
      if (objektOptions?.options?.length) {
        onSolutionFieldChange('heatSource', objektOptions.options[0].heatSource);
        if (objektOptions.options[0].spitzenLastBedarf) {
          onSolutionFieldChange('spitzenLast', 'Keine');
        } else {
          onSolutionFieldChange('spitzenLast', '');
        }
      }
      onSolutionFieldChange('sonderPotenzial', '');
    } else {
      onSolutionFieldChange('heatSource', '');
      onSolutionFieldChange('spitzenLast', '');
      onSolutionFieldChange('sonderPotenzial', '');
    }
  };

  const handleHeatSourceChange = (e) => {
    const selected = e.target.value;
    onSolutionFieldChange('heatSource', selected);
    onSolutionFieldChange('spitzenLast', '');
  };

  const handleSpitzenLastChange = (e) => {
    onSolutionFieldChange('spitzenLast', e.target.value);
  };

  const handleSonderPotenzialChange = (e) => {
    onSolutionFieldChange('sonderPotenzial', e.target.value);
  };

  const handleSpitzenLastPercentageChange = (e) => {
    let val = parseInt(e.target.value);
    if (val > 50) val = 50;
    onSolutionFieldChange('spitzenLastPercentage', val);
  };

  const handleSonderPotenzialPercentageChange = (e) => {
    onSolutionFieldChange('sonderPotenzialPercentage', parseInt(e.target.value));
  };


  const selectedClassOptions = solution_builder_options.find(
    (option) => option.solutionClass === solutionClass
  );
  const heatSourceOptions = selectedClassOptions ? selectedClassOptions.options : [];

  const selectedHeatSourceObj = heatSourceOptions.find(
    (option) => option.heatSource === heatSource
  );
  const showSpitzenLast = selectedHeatSourceObj && selectedHeatSourceObj.spitzenLastBedarf;

  return (
    <div className='solution-card-content'>
      <div className='solution-card' >
        <div className='select-group'>
          <label htmlFor='solution-class-select'>Art</label>
          <select
            id='solution-class-select'
            value={solutionClass}
            onChange={handleSolutionClassChange}
          >
            <option value=''>Bitte wählen</option>
            {solution_builder_options.map((option) => (
              <option key={option.solutionClass} value={option.solutionClass}>
                {option.solutionClass}
              </option>
            ))}
          </select>
        </div>

        <div className='select-group'>
          <label htmlFor='heat-source-select'>Hauptwärmequelle</label>
          {solutionClass === 'Fern' ? (
            <input type='text' value='Fernwärme Anbieter' readOnly />
          ) : (
            <select
              id='heat-source-select'
              value={heatSource}
              onChange={handleHeatSourceChange}
            >
              <option value=''>Bitte wählen</option>
              {heatSourceOptions.map((option) => (
                <option key={option.heatSource} value={option.heatSource}>
                  {option.heatSource}
                </option>
              ))}
            </select>
          )}
        </div>

        {showSpitzenLast && (
          <div className='slider-group'>
            <div className='select-group-40'>
              <label htmlFor='spitzenlast-select'>Spitzenlast</label>
              <select
                id='spitzenlast-select'
                value={spitzenLast}
                onChange={handleSpitzenLastChange}
              >
                <option value=''>Bitte wählen</option>
                {spitzenLastOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

            </div>
            {spitzenLast !== '' && spitzenLast !== 'Keine' && (
              <div className='percentage-slider-container'>
                <input
                  type='range'
                  min='0'
                  max='100'
                  value={spitzenLastPercentage}
                  onChange={handleSpitzenLastPercentageChange}
                  className='percentage-slider'
                />
                <span>{spitzenLastPercentage}%</span>
              </div>
            )}
          </div>
        )}

        {solutionClass === 'Netz' && (
          <div className='slider-group'>

            <div className='select-group-40'>
              <label htmlFor='sonderpotenzial-select'>Zusätliche Wärmequellen</label>
              <select
                id='sonderpotenzial-select'
                value={sonderPotenzial}
                onChange={handleSonderPotenzialChange}
              >
                <option value=''>Bitte wählen</option>
                {netzSonderPotenziale.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {sonderPotenzial !== '' && sonderPotenzial !== 'Keine' && (
              <div className='percentage-slider-container'>
                <input
                  type='range'
                  min='0'
                  max='90'
                  value={sonderPotenzialPercentage}
                  onChange={handleSonderPotenzialPercentageChange}
                  className='percentage-slider'
                />
                <span>{sonderPotenzialPercentage}%</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionCard;
